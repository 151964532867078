<template>
  <b-overlay :show="loading">
    <b-card class="mb-1">
      <strong
        >Harap pahami dahulu alur rasionalisasi Kedinasan
        <a
          href="#"
          @click.prevent="
            $router.push(
              `/master-admin/rasionalisasi/kedinasan?p=${$route.params?.id}`
            )
          "
          >disini.</a
        ></strong
      >
    </b-card>
    <b-card>
      <header class="mb-1">
        <strong
          >Silahkan tentukan <i class="text-danger">passing grade</i> untuk Sub
          Kategori</strong
        >
        yang ditandai sebagai <strong>Sub Kategori Psikotest</strong>
      </header>
      <section class="mb-4">
        <b-table :fields="subCatFields" :items="subCategories">
          <template #cell(index)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(passing_grade)="{ item }">
            <small class="text-danger" v-if="item.mark_as_psikotest == 0">
              <i>Penilaian ditentukan passing grade bobot pada tabel dibawah</i>
            </small>
            <strong v-else>
              {{ item.passing_grade }}
            </strong>
          </template>
          <template #cell(mark_as_psikotest)="{ item }">
            <b-tooltip target="mark-as-psikotest-on" triggers="hover">
              Jika ditandai sebagai psikotest, penilaian ujian di sub kategori
              ini akan menggunakan bobot persentase
            </b-tooltip>
            <span
              v-if="item.mark_as_psikotest == 1"
              id="mark-as-psikotest-on"
              class="cursor-pointer bg-success"
              style="padding: 5px; border-radius: 20px"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="21"
                class="text-light"
              />
            </span>
            <b-tooltip target="mark-as-psikotest-off" triggers="hover">
              Jika tidak ditandai sebagai psikotest, penilaian ujian di sub
              kategori ini akan menggunakan passing grade di jadwal
            </b-tooltip>
            <span
              v-if="item.mark_as_psikotest == 0"
              id="mark-as-psikotest-off"
              class="cursor-pointer bg-danger"
              style="padding: 5px; border-radius: 20px"
            >
              <feather-icon icon="XCircleIcon" size="21" class="text-light" />
            </span>
          </template>
          <template #cell(action)="{ item }">
            <strong
              ><a href="#" @click.prevent="editSubcat(item)" class="text-info"
                >Edit</a
              ></strong
            >
          </template>
        </b-table>
      </section>

      <section class="mb-2" v-if="thereIsNonPsikotestSubcat">
        <header class="mb-2">
          <strong
            >Silahkan tentukan passing grade bobot setiap ujian-ujian dengan Sub
            Kategori yang tidak ditandai sebagai Psikotest dibawah ini</strong
          >
        </header>
        <table class="table table-bordered w-100" border="1">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Ujian</th>
              <th>Jumlah Passing Grade Bobot</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(subtest, i) in subtestsNonPsikotest" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ subtest?.ujian?.name }}</td>
              <td>
                <strong>{{ subtest?.bobot?.value }}</strong>
              </td>
              <td>
                <strong
                  ><a
                    href="#"
                    class="text-info"
                    @click.prevent="editBobot(subtest, 0)"
                    >Edit</a
                  ></strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section class="mb-2" v-if="thereIsPsikotestSubcat">
        <header class="mb-2">
          <strong
            >Silahkan tentukan bobot persentase setiap ujian-ujian dengan Sub
            Kategori yang sudah ditandai sebagai Psikotest dibawah ini</strong
          >
        </header>
        <table class="table table-bordered w-100" border="1">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Ujian</th>
              <th>Bobot Persentase</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in subtests" :key="i">
              <td v-if="item.is_parent" colspan="4">
                <i class="text-dark"
                  >{{ item.name }}: Total Bobot (<strong
                    :class="item.total_percentage == 100 ? 'text-success' : ''"
                    >{{ item.total_percentage }}%</strong
                  >)
                  <small class="text-danger" v-if="item.total_percentage != 100"
                    >*pastikan total bobot sub kategori 100%</small
                  >
                </i>
              </td>
              <th v-if="!item.is_parent">
                {{ item?.index }}
              </th>
              <th v-if="!item.is_parent">
                {{ item?.ujian?.name }}
              </th>
              <th v-if="!item.is_parent">
                <strong class="text-primary"
                  >{{ item.bobot?.value_percentage }}%</strong
                >
              </th>
              <th v-if="!item.is_parent">
                <strong
                  ><a
                    href="#"
                    class="text-info"
                    @click.prevent="editBobot(item, 1)"
                    >Edit</a
                  ></strong
                >
              </th>
            </tr>
          </tbody>
        </table>
      </section>
      <section v-else>
        <b-alert class="p-1" show variant="danger">
          <strong
            >Belum terdapat Sub Kategori yang ditandai sebagai Psikotest</strong
          >, silahkan tandai sebagai Psikotest salah satu Sub Kategori untuk
          mengatur Rasionalisasi Kedinasan
        </b-alert>
      </section>
    </b-card>

    <!-- Modal edit subcat -->
    <b-modal no-close-on-backdrop no-close-on-esc id="modal-subcat">
      <section>
        <h5 class="mb-1">
          <strong
            >Sub Kategori:
            <span class="text-danger">{{ formSubcat?.name }}</span></strong
          >
        </h5>

        <div class="form-group">
          <label for=""
            >Tandai Sub Kategori sebagai <strong>Psikotest</strong>?</label
          >
          <b-form-radio
            v-model="formSubcat.mark_as_psikotest"
            name="mark_as_psikotest"
            style="margin-bottom: 10px"
            :value="true"
            >Ya</b-form-radio
          >
          <b-form-radio
            v-model="formSubcat.mark_as_psikotest"
            name="mark_as_psikotest"
            style="margin-bottom: 10px"
            :value="false"
            >Tidak</b-form-radio
          >
        </div>

        <div class="form-group" v-if="formSubcat.mark_as_psikotest">
          <label for="">Passing Grade (1 - 100)</label>
          <b-form-input v-model="formSubcat.passing_grade" />
        </div>
      </section>

      <template #modal-footer>
        <b-button variant="primary" @click.prevent="updateSubcat"
          >Simpan</b-button
        >
      </template>
    </b-modal>
    <!-- / -->

    <!-- Mdal BObot Subtest -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="modal-bobot"
      :title="
        formBobot.mark_as_psikotest == 1
          ? 'Atur Bobot Persentase Subtest'
          : 'Atur Bobot Passing Grade Subtest'
      "
    >
      <section>
        <h5 class="mb-1">
          <i
            >Ujian: <strong>{{ formBobot?.name }}</strong></i
          >
        </h5>
        <div class="form-group">
          <label for=""
            ><strong>{{
              formBobot.mark_as_psikotest == 1
                ? `Bobot Ujian (dalam %)`
                : `Bobot Ujian (dalam nominal)`
            }}</strong></label
          >
          <b-form-input
            v-if="formBobot.mark_as_psikotest == 1"
            type="number"
            v-model="formBobot.value_percentage"
          />
          <b-form-input
            v-if="formBobot.mark_as_psikotest == 0"
            type="number"
            v-model="formBobot.value"
          />
        </div>
      </section>

      <template #modal-footer>
        <b-button variant="primary" @click.prevent="updateBobot"
          >Simpan</b-button
        >
      </template>
    </b-modal>
    <!-- / -->
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BAlert,
  BButton,
  BModal,
  BFormInput,
  BFormRadio,
  BTooltip,
  BTable,
  BCard,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BAlert,
    BButton,
    BModal,
    BFormInput,
    BFormRadio,
    BTooltip,
    BTable,
    BCard,
  },
  computed: {
    thereIsNonPsikotestSubcat() {
      return this.subCategories.some((subcat) => subcat.mark_as_psikotest == 0);
    },
    thereIsPsikotestSubcat() {
      return this.subCategories.some((subcat) => subcat.mark_as_psikotest == 1);
    },
  },
  data: () => ({
    subtestsNonPsikotest: [],
    subCatFields: [
      { key: "index", label: "No", sortable: true, class: "text-center" },
      {
        key: "name",
        label: "Sub Kategori",
        sortable: true,
        class: "text-left",
      },
      {
        key: "passing_grade",
        label: "Passing Grade",
        sortable: true,
        class: "text-center",
      },
      {
        key: "mark_as_psikotest",
        label: "Tandai Sebagai Psikotest",
        sortable: true,
        class: "text-center",
      },
      { key: "action", label: "#", sortable: false, class: "text-center" },
    ],
    subCategories: [],
    formSubcat: {
      id: null,
      paket_id: null,
      name: null,
      passing_grade: 0,
      mark_as_psikotest: false,
    },
    formBobot: {
      id: null,
      name: null,
      paket_id: null,
      subtest_id: null,
      value: 0,
      value_percentage: 0,
      mark_as_psikotest: 1,
    },
    loading: false,
    subtests: [],
  }),
  methods: {
    async updateBobot() {
      // set payload
      const payload = {
        paket_id: this.formBobot.paket_id,
        subtest_id: this.formBobot.subtest_id,
        value:
          this.formBobot.mark_as_psikotest == 1
            ? parseFloat(this.formBobot.value_percentage / 100)
            : parseInt(this.formBobot.value),
      };

      if (this.formBobot.id) {
        payload.id = this.formBobot.id;
      }

      try {
        this.$bvModal.hide("modal-bobot");
        this.loading = true;
        await this.$store.dispatch("rasionalisasi/CUDbintara", [payload]);
        await this.getSubtests();
        this.loading = false;
      } catch (e) {
        this.$bvModal.show("modal-bobot");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    editBobot(subtest, markAsPsikotest = 1) {
      this.formBobot.id = subtest?.bobot.bobot_id;
      this.formBobot.name = subtest?.ujian.name;
      this.formBobot.paket_id = subtest.paket_id;
      this.formBobot.subtest_id = subtest.id;
      this.formBobot.value_percentage = subtest.bobot.value_percentage;
      this.formBobot.value = subtest.bobot.value;
      this.formBobot.mark_as_psikotest = markAsPsikotest;

      this.$bvModal.show("modal-bobot");
    },
    resetFormSubcat() {
      this.formSubcat.id = null;
      this.formSubcat.paket_id = null;
      this.formSubcat.name = null;
      this.formSubcat.mark_as_psikotest = false;
      this.formSubcat.passing_grade = 0;
    },
    async getSubtests() {
      try {
        this.subtests = [];
        const params = {
          paket_id: this.$route.params.id,
        };

        let response = await this.$store.dispatch(
          "adminujian/subtesPaketWithBobot",
          params
        );
        response = response?.data;

        // di group berdasarkan subcat
        let subcats = Object.assign([], this.subCategories);
        let subcatsPsikotest = subcats.filter(
          (subcat) => subcat.mark_as_psikotest == 1
        );
        subcatsPsikotest = subcatsPsikotest.map((subcat) => {
          const subtests = response
            .filter((subtest) => {
              return subtest?.subcat_id == subcat.id;
            })
            .map((subtest, i) => {
              subtest.index = i + 1;
              return subtest;
            });
          subcat.items = subtests;

          return subcat;
        });

        let subcatsNonPsikotest = subcats.filter(
          (subcat) => subcat.mark_as_psikotest == 0
        );
        let resultNonPsikotest = [];
        subcatsNonPsikotest = subcatsNonPsikotest.forEach((subcat) => {
          const subtests = response
            .filter((subtest) => {
              return subtest?.subcat_id == subcat.id;
            })
            .map((subtest, i) => {
              subtest.index = i + 1;
              return subtest;
            });
          ``;
          resultNonPsikotest.push(...subtests);
        });

        this.subtestsNonPsikotest = resultNonPsikotest;

        let results = [];
        for (let subcat of subcatsPsikotest) {
          // get total percentage per subcat
          const totalPercentage =
            subcat.items.length > 0
              ? subcat.items.reduce(
                  (total, subtest) =>
                    (total += subtest.bobot?.value_percentage),
                  0
                )
              : 0;
          // save subcat
          results.push({
            id: subcat.id,
            name: subcat.name,
            passing_grade: subcat.passing_grade,
            total_percentage: totalPercentage,
            is_parent: true,
          });
          // save subtests
          if (subcat.items.length > 0) {
            for (let subtest of subcat.items) {
              results.push(subtest);
            }
          }
        }

        this.subtests = results;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async updateSubcat() {
      const payload = this.formSubcat;

      try {
        this.$bvModal.hide("modal-subcat");
        this.loading = true;
        await this.$store.dispatch("adminujian/CUDsubtesKatPaket", [payload]);
        await this.getSubCategories();
        this.resetFormSubcat();
        this.loading = false;
      } catch (e) {
        this.$bvModal.show("modal-subcat");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    editSubcat(subcat) {
      this.formSubcat.id = subcat.id;
      this.formSubcat.paket_id = subcat.paket_id;
      this.formSubcat.name = subcat.name;
      this.formSubcat.passing_grade = subcat.passing_grade;
      this.formSubcat.mark_as_psikotest =
        subcat.mark_as_psikotest == 1 ? true : false;

      this.$bvModal.show("modal-subcat");
    },
    async getSubCategories() {
      try {
        const params = {
          paket_id: this.$route.params.id,
        };

        const response = await this.$store.dispatch(
          "paketTryout/getSubcat",
          params
        );
        this.subCategories = response?.data;
        if (this.thereIsPsikotestSubcat) {
          this.getSubtests();
        }
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },
  mounted() {
    this.getSubCategories();
  },
};
</script>
