<template>
  <b-overlay :show="loading">
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Member : {{ this.members.length }}</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click.prevent="openMemberModal"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah
                  Member</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-card-title>
        <section>
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filtermember"
                    type="search"
                    placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getMemberPaket()"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filtermember"
                      @click="
                        filtermember = '';
                        getMemberPaket();
                      "
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :fields="memberFields"
                :items="members"
              >
                <template #cell(index)="{ index }">
                  {{ ++index }}
                </template>
                <template #cell(type_member)="{ item }">
                  <b-badge>{{ item.type_member }}</b-badge>
                </template>
                <template #cell(from_time)="{ item }">
                  <strong>{{ getFullTanggal(item) }}</strong>
                </template>
                <template #cell(active)="{ item }">
                  <feather-icon
                    size="24"
                    v-if="item.active == 1"
                    icon="CheckIcon"
                    class="text-success"
                  ></feather-icon>
                  <feather-icon
                    size="24"
                    v-else
                    icon="XIcon"
                    class="text-danger"
                  ></feather-icon>
                </template>
                <template #cell(action)="{ item }">
                  <feather-icon
                    v-if="item.active == 0"
                    size="24"
                    icon="CheckCircleIcon"
                    @click.prevent="activateMember(item)"
                    class="cursor-pointer text-success mr-1"
                  ></feather-icon>
                  <feather-icon
                    size="24"
                    icon="TrashIcon"
                    @click.prevent="removeMember(item)"
                    class="cursor-pointer text-danger"
                  ></feather-icon>
                </template>
              </b-table>
              <b-pagination
                :total-rows="totalRows"
                v-model="currentPage"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </section>

        <!-- modal -->
        <b-modal
          title="Form Tambah Member Paket"
          id="member-modal"
          no-close-on-backdrop
          no-close-on-esc
        >
          <b-form>
            <b-form-group label="Pilih Peserta Member" class="mb-2">
              <v-select
                v-model="selectedMembers"
                :options="userMemberList"
                label="name"
                multiple
              ></v-select>
            </b-form-group>
            <b-form-group label="Pilih Tanggal Mulai">
              <b-form-input type="date" v-model="startDate"></b-form-input>
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit"
              >Submit</b-button
            >
          </template>
        </b-modal>
        <!-- /Modal -->
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BPagination,
  BFormTextarea,
  BOverlay,
  BTable,
  BBadge,
  BButton,
  BModal,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  // props: ['members', 'userList'],
  components: {
    BForm,
    BPagination,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BOverlay,
    BTable,
    BBadge,
    BButton,
    vSelect,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
  },
  data: () => ({
    members: [],
    userList: [],
    userMemberList: [],
    loading: false,
    startDate: null,
    selectedMembers: [],
    memberFields: [
      { key: "index", label: "No" },
      { key: "name", label: "Nama Peserta", sortable: true },
      { key: "type_member", label: "Jalur Daftar", sortable: true },
      { key: "from_time", label: "Masa Berlangganan", sortable: true },
      { key: "active", label: "Keaktifan Member" },
      { key: "action", label: "#" },
    ],
    optFilter: [
      { id: "name", value: "Nama Peserta" },
      { id: "type_member", value: "Jalur Daftar" },
    ],
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filtermember: null,
    filterOn: [],
    search: null,
    localMembers: [],
  }),
  watch: {
    // members(val) {
    //     if (val) {
    //         this.localMembers = val
    //     }
    // }
    perPage(value) {
      if (value) {
        this.getMemberPaket();
      }
    },
    filtermember(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getMemberPaket();
      }
    },
    currentPage(val) {
      if (val) {
        this.getMemberPaket();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.memberFields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    isValidForm() {
      if (!this.startDate || this.selectedMembers.length < 1) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    activateMember(item) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Anda akan mengaktifkan member ini?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (res) => {
        if (res.value) {
          const payload = {
            paket_id: item.paket_id,
            user_id: item.user_id,
            from_time: item.from_time,
          };

          try {
            this.loading = true;
            await this.$store.dispatch("paketTryout/storeMemberPaket", [
              payload,
            ]);
            this.getMemberPaket();
            this.loading = false;
          } catch (e) {
            this.displayError(e);
            return false;
          }
        }
      });
    },
    removeMember(item) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Anda akan menghapus member ini?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const payload = {
            id: item.id,
            fungsi: 1,
          };

          try {
            this.loading = true;
            const response = await this.$store.dispatch(
              "paketTryout/storeMemberPaket",
              [payload]
            );
            this.loading = false;
            this.displaySuccess({
              message:
                response.data && response.data.message
                  ? response.data.message
                  : "Member paket berhasil diperbaharui",
            });

            setTimeout(() => window.location.reload(), 1500);
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async submit() {
      if (!this.isValidForm) {
        this.displayError({
          message: "Harap lengkapi form member dengan lengkap!",
        });
        return false;
      }

      const payloads = this.selectedMembers.map((user) => ({
        paket_id: this.$route.params.id,
        user_id: user.id,
        from_time: this.startDate,
      }));

      try {
        this.loading = true;
        this.$bvModal.hide("member-modal");
        const response = await this.$store.dispatch(
          "paketTryout/storeMemberPaket",
          payloads
        );

        this.displaySuccess({
          message:
            response.data && response.data.message
              ? response.data.message
              : "Member paket berhasil diperbaharui",
        });

        this.loading = false;
        setTimeout(() => window.location.reload(), 1500);
      } catch (e) {
        this.$bvModal.show("member-modal");
        this.loading = false;
        this.displayError(e);
      }
    },
    openMemberModal() {
      this.$bvModal.show("member-modal");
    },
    getFullTanggal(member) {
      const fromTime = member.from_time,
        toTime = member.to_time;

      if (!fromTime || !toTime) {
        return "Waktu belum ditentukan";
      }

      const arrFromTime = fromTime.split(" "),
        dateFromTime = arrFromTime[0],
        timeFromTime = arrFromTime[1],
        jamFromTime = timeFromTime.split(":");

      const arrToTime = toTime.split(" "),
        dateToTime = arrToTime[0],
        timeToTime = arrToTime[1],
        jamToTime = timeToTime.split(":");

      return `${dateFromTime.split("-").reverse().join("/")} ${
        jamFromTime[0]
      }:${jamFromTime[1]} sampai ${dateToTime.split("-").reverse().join("/")} ${
        jamToTime[0]
      }:${jamToTime[1]}`;
    },

    async getMemberPaket() {
      // const params = {
      //     paket_id: this.$route.params.id,
      // };

      // try {
      //     const response = await this.$store.dispatch(
      //         "paketTryout/indexMemberPaket",
      //         params
      //     );
      //     this.members =
      //         response.data && response.data.data ? response.data.data : [];
      // } catch (e) {
      //     this.displayError(e);
      //     return false;
      // }
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filtermember != null ? this.filtermember : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        paket_id: this.$route.params.id,
      };

      // if (this.filtermember.length > 2) {
      //     payload.search = this.filtermember;
      // }
      this.$store
        .dispatch("paketTryout/indexMemberPaket", payload)
        .then((response) => {
          let data = response.data.data;
          let total = response.data.totalRecords;
          this.loading = false;
          this.members = data;
          this.totalRows = total;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getUserTypeMember() {
      const params = {
        level_id: 1,
        type_member: "member",
      };

      try {
        const response = await this.$store.dispatch("pengguna/index", params);
        let userList =
          response.data && response.data.data ? response.data.data : [];
        userList = userList.filter((user) => user.name != "");
        userList = userList.map((user) => ({
          id: user.id,
          name: user.name,
        }));
        this.userMemberList = userList;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },
  async mounted() {
    this.getMemberPaket();
    this.getUserTypeMember();
  },
};
</script>
