<template>
  <section>
    <!-- <b-row> -->
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Ujian-ujian Paket : {{ paket.name }}</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button variant="outline-primary" v-b-modal.modal-sm
                  @click="paketHasSubCategory ? ModalShow() : ModalShowSub()" class="btn-icon">
                  <feather-icon icon="PlusIcon" class="mr-25" />{{
                    paketHasSubCategory
                    ? "Tambah Sub Kategori"
                    : "Tambah Ujian"
                  }}</b-button>
              </div>
              <b-modal v-model="Modal" id="modal-tambah" centered size="sm" :title="titleModal">
                <b-card-text>
                  <b-form>
                    <b-form-group label="Nama Sub Kategori Paket" label-for="subcat">
                      <b-form-input id="subcat" v-model="form.name" placeholder="Ex: Tes Skolastik" />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="outline-success"
                      class="mr-1 btn-icon" @click.prevent="Tambah">
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                      class="float-right btn-icon" @click.prevent="tutupModal">
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <!-- SUB -->
              <b-modal v-model="ModalSub" id="modal-tambah-sub" centered size="xl" :title="titleModal">
                <b-card-text>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="Buat baru atau pilih yang sudah ada?">
                        <b-form-radio v-model="formSub.adaujian" name="some-radios" value="1">Buat baru ujian</b-form-radio>
                        <b-form-radio v-model="formSub.adaujian" name="some-radios" value="0">Pilih Ujian yang
                          sudah
                          dibuat sebelumnya</b-form-radio>
                      </b-form-group>
                    </b-col>
                    <template v-if="formSub.adaujian == 0">
                      <b-col sm="3">
                        <b-form-group v-if="paketHasSubCategory" label="Sub Kategori" label-for="Subtest">
                          <b-form-select id="Subtest" v-model="formSub.subcat_id" :options="paketSubtestDataParent"
                            placeholder="Sub Kategori" disabled />
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group label="Pilih Ujian" label-for="ujian">
                          <b-form-select id="ujian" v-model="formSub.ujian_id" :options="optUjian" placeholder="Ujian"
                            class="mb-1" />
                        </b-form-group>
                      </b-col>
                    </template>
                  </b-row>
                  <b-row>
                    <template v-if="formSub.adaujian == 1">
                      <b-col sm="4">
                        <b-form-group v-if="paketHasSubCategory" label="Sub Kategori" label-for="Subtest">
                          <b-form-select id="Subtest" v-model="formSub.subcat_id" :options="paketSubtestDataParent"
                            placeholder="Sub Kategori" disabled />
                        </b-form-group>
                      </b-col>
                      <b-col sm="8">
                        <b-form-group label="Nama Ujian" label-for="nama-paket">
                          <b-form-input v-model="ujianDataId.name" placeholder="Nama Ujian" name="nama-paket" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Nama Ujian (singkat)" label-for="harga">
                          <b-form-input v-model="ujianDataId.shortname" name="shortname" placeholder="shortname" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Acak Soal" label-for="acak">
                          <b-form-select v-model="ujianDataId.shuffle_question" :options="optquestion" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Tampilkan Hasil" label-for="show_result">
                          <b-form-select v-model="ujianDataId.show_result" :options="optresult" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12">
                        <b-form-group label="Instruksi" label-for="instruksi">
                          <quill-editor id="Konten" v-model="ujianDataId.instruction" :options="editor_options">
                          </quill-editor>
                        </b-form-group>
                      </b-col>
                    </template>
                  </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="outline-success"
                      class="mr-1 btn-icon" @click.prevent="TambahSub">
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                      class="float-right btn-icon" @click.prevent="tutupModal">
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row>
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <!-- <b-row> -->
          <section v-if="paketHasSubCategory">
            <app-collapse accordion type="border" v-for="paket in paketSubtestData" :key="paket.id">
              <app-collapse-item :title="paket.name + ' (' + paket.items.length + ')'">
                <div align="right">
                  <b-dropdown id="dropdown-1" :dropright="true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" text="Aksi"
                    right class="mb-2" variant="primary">
                    <!-- <b-dropdown-item v-b-modal.modal-lg @click="ModalShowSub(paket)"
                      ><feather-icon icon="PlusIcon" class="mr-50" /><span
                        >Tambah Ujian</span
                      >
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="ModalUbah(paket)"><feather-icon icon="EditIcon" class="mr-50" /><span>Edit
                        Sub Kategori</span></b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(paket)"><feather-icon icon="TrashIcon" class="mr-50" /><span>Hapus
                        Sub Kategori</span></b-dropdown-item>
                  </b-dropdown>
                  <b-button class="mb-2 ml-2" variant="outline-primary" v-b-modal.modal-lg
                    @click="ModalShowSub(paket)"><feather-icon icon="PlusIcon" class="mr-50" />Tambah Ujian</b-button>
                </div>
                <div v-if="paket.items.length > 0">
                  <b-table-lite responsive :fields="fieldsItems" :items="paket.items">
                    <template #cell(title)="data">
                      <h5 class="mt-0">
                        <b>{{ data.item.nama_ujian }} </b>
                      </h5>
                      <p class="mb-2">
                        <b-badge variant="light-secondary" class="ml-25">
                          <feather-icon icon="ClipboardIcon" class="mr-25" />
                          <span class="ml-25">{{ data.item.sum_soal }} Soal</span>
                        </b-badge>
                      </p>
                    </template>
                    <template #cell(action)="row">
                      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item :to="{
                          name: 'admin-ujian-detail',
                          params: {
                            id: row.item.ujian_id
                          },
                          query: {
                            category_paket_id:
                              row.item.category_paket_id,
                              ujian_id: row.item.ujian_id
                          },
                        }">
                          <feather-icon icon="SettingsIcon" class="mr-50" />
                          <span>Detail & Buat Soal</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="ModalHapusSub(row.item)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Hapus</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>

                    <!-- Optional default data cell scoped slot -->
                    <template #cell()="data">
                      {{ data.value }}
                    </template>
                  </b-table-lite>
                </div>
                <div v-else>
                  <p>Tidak ada data</p>
                </div>
              </app-collapse-item>
            </app-collapse>
          </section>
          <section v-else>
            <div class="flex justify-center mb-3"></div>
            <b-table-lite v-if="paketSubtestData.length > 0" responsive :fields="subtestField" :items="paketSubtestData">
              <template #cell(index)="{ index }">
                {{ ++index }}
              </template>
              <template #cell(title)="data">
                <strong>
                  <h5 class="mt-0">
                    {{ data.item.nama_ujian }}
                  </h5>
                </strong>
              </template>
              <template #cell(sum_soal)="row">
                <p>
                  <span>{{ row.item.sum_soal }} Soal</span>
                </p>
              </template>
              <template #cell(action)="row">
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item :to="{
                          name: 'admin-ujian-detail',
                          params: {
                            id: row.item.ujian_id
                          },
                          query: {
                            category_paket_id:
                              row.item.category_paket_id,
                              ujian_id: row.item.ujian_id
                          },
                        }">
                    <feather-icon icon="SettingsIcon" class="mr-50" />
                    <span>Detail & Buat Soal</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="ModalUbahSub(row.item)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item @click="ModalHapusSub(row.item)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Hapus</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table-lite>
            <p v-else>
              <strong class="text-danger">
                <i>Data Subtes kosong, harap buat.</i>
              </strong>
            </p>
          </section>
          <!-- </b-row> -->
        </b-card-text>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BTableLite,
  BMedia,
  BImg,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormRadio,
  BFormTextarea,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["paket"],
  components: {
    quillEditor,
    BTableLite,
    BMedia,
    BImg,
    AppCollapse,
    AppCollapseItem,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //Ujian
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      ujianDataId: {},
      //
      subtestField: [
        { key: "index", label: "No" },
        { key: "nama_ujian", label: "Subtest" },
        { key: "sum_soal", label: "Jumlah Soal" },
        { key: "action", label: "#" },
      ],
      optUjian: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketSubtestData: [],
      form: {
        id: null,
        name: null,
        paket_id: null,
      },
      formSub: {
        id: null,
        ujian_id: null,
        subcat_id: null,
      },
      paketSubtestDataParent: [],
      paketSubtestDataItems: [],
      //Modal
      titleModal: null,
      Modal: false,
      ModalSub: false,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      //Table
      fields: [
        { key: "show_details", label: "#" },
        { key: "index", label: "No" },
        { key: "title", label: "Ujian", sortable: true },
        { key: "action", label: "#" },
      ],
      fieldsItems: [
        { key: "title", label: "Nama Ujian", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Ujian" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Subtest Paket",
      search: null,
      idd: null,
      currentSubcatId: null,
    };
  },
  watch: {},
  computed: {
    paketLength() {
      if (this.paket && this.paket.items) {
        return this.paket.items.length;
      }

      return 0;
    },
    paketHasSubCategory() {
      if (this.paket && this.paket.is_contain_subcat == 1) {
        return true;
      }

      return false;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    createNewUjian() {
      // save pake_id, sub kategori kalo ada
      const {
        id: paketId,
        category_id: categoryId,
        is_contain_subcat: isContainSubcat,
      } = this.paket;

      let createUjianLink = `/master-admin/ujian-admin/a?paket=${paketId}&cat=${categoryId}`;
      if (isContainSubcat == 1 && this.currentSubcatId) {
        createUjianLink += `&subcat=${this.currentSubcatId}`;
      }

      // detail link master-admin/paket-tryout/detail/7?active_tab=subtest
      this.$router.push(createUjianLink);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.ModalSub = false;
      this.resetForm();
      this.getDataPaketSubtest();
    },
    resetForm() {
      this.form = {
        id: null,
        text: null,
        paket_id: null,
      };
      this.formSub = {
        id: null,
        title: null,
        paket_id: null,
        parent_id: null,
        timer: null,
        sum_soal: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Sub Kategori Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Subtest Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const item = {
            id: data.id,
            paket_id: this.$route.params.id,
            fungsi: 1,
          };
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("adminujian/CUDsubtesKatPaket", [item])
            .then(() => {
              this.getDataPaketSubtest();
              this.displaySuccess({
                text: "Sub Kategori berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "AlertCircleIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      // if (this.form.name == null || this.form.name == "") {
      //   this.pesanGagal();
      //   return false;
      // }
      let payload = {
        name: this.form.name,
        paket_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("adminujian/CUDsubtesKatPaket", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getDataPaketSubtest();
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataPaketSubtest() {
      const params = {
        paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", params)
        .then((response) => {
          let paketSubtestData = response.data.data;
          this.paketSubtestData = paketSubtestData;
          this.totalRows = this.paketSubtestData.length;
          this.paketSubtestDataItems = paketSubtestData.items;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    async getDataPaketSubtestParent() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: this.$route.params.id,
        ujian_id: 0,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          let paketSubtestDataParent = response.data.data;
          paketSubtestDataParent.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.paketSubtestDataParent = paketSubtestDataParent;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    ModalShowSub(paket = null) {
      this.getDataPaketSubtestParent();
      this.getDataUjian();
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Ujian";
      this.ModalSub = true;
      if (paket) {
        this.formSub.subcat_id = paket.id;
      }
      const { is_contain_subcat: isContainSubcat } = this.paket;
      this.currentSubcatId = isContainSubcat == 1 ? paket?.id : null;
    },
    ModalUbahSub(data) {
      this.getDataPaketSubtestParent();
      this.getDataUjian();
      this.id = data.ujian.id;
      // this.ujianDataId = data;
      this.ujianDataId.name = data.ujian.name;
      this.ujianDataId.shortname = data.ujian.shortname;
      this.ujianDataId.shuffle_question = data.ujian.shuffle_question;
      this.ujianDataId.show_result = data.ujian.show_result;
      this.ujianDataId.instruction = data.ujian.instruction;
      this.formSub.id = data.id;
      this.formSub.ujian_id = data.ujian_id;
      if (data.subcat_id) {
        this.formSub.subcat_id = data.subcat_id;
      }
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.ModalSub = true;
      // this.ModalEdit = true;
    },
    ModalHapusSub(dataSub) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const sub = {
            id: dataSub.id,
            fungsi: 1, //soft delete
          };
          this.$store
            .dispatch("adminujian/storeSubtestPaket", [sub])
            .then(() => {
              this.getDataPaketSubtest();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    TambahSub() {
      if (this.formSub.adaujian == 1) {
        let payloadUjian = {
          user_id: this.user.id,
          category_paket_id: this.paket.category_id,
          name: this.ujianDataId.name,
          shortname: this.ujianDataId.shortname,
          show_result: this.ujianDataId.show_result,
          shuffle_question: this.ujianDataId.shuffle_question,
          instruction: this.ujianDataId.instruction,
        };

        if (this.id) {
          payloadUjian.id = this.id;
        }

        this.loading = true;
        this.$store
          .dispatch("adminujian/save", [payloadUjian])
          .then((res) => {
            let respon = res.data;
            this.loading = false;
            this.Modal = false;
            // this.ModalEdit = false;
            this.displaySuccess({
              text: "Ujian berhasil di" + this.activeAction,
            });
            if (this.id == null) {
              let payload = {
                paket_id: this.$route.params.id,
                ujian_id: respon.id,
              };

              if (this.paketHasSubCategory) {
                payload.subcat_id = this.formSub.subcat_id;
              }

              if (this.id) {
                payload.id = this.id;
              }

              this.$store
                .dispatch("adminujian/storeSubtestPaket", [payload])
                .then(() => {
                  this.id = null;
                  this.displaySuccess({
                    text:
                      "Ujian berhasil di" +
                      this.activeAction +
                      " di Sub Kategori",
                  });
                  this.id = null;
                  this.ModalSub = false;

                  this.resetForm();
                  const isPaketContainSubcat = this.paket?.is_contain_subcat == 1;
                  if (isPaketContainSubcat) {
                    window.location.href = `/master-admin/paket-tryout/detail/${this.$route.params?.id}?active_tab=subtest`;
                  } else {
                    this.getDataPaketSubtest();
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                  return false;
                });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.displayError(e);
            return false;
          });
      }
      if (this.formSub.adaujian == 0) {
        let payload = {
          paket_id: this.$route.params.id,
          ujian_id: this.formSub.ujian_id,
        };

        if (this.paketHasSubCategory) {
          payload.subcat_id = this.formSub.subcat_id;
        }

        if (this.id) {
          payload.id = this.id;
        }

        this.$store
          .dispatch("adminujian/storeSubtestPaket", [payload])
          .then(() => {
            this.id = null;
            this.displaySuccess({
              text:
                "Ujian berhasil di" +
                this.activeAction +
                " di Sub Kategori",
            });
            this.id = null;
            this.ModalSub = false;

            this.resetForm();
            const isPaketContainSubcat = this.paket?.is_contain_subcat == 1;
            if (isPaketContainSubcat) {
              window.location.href = `/master-admin/paket-tryout/detail/${this.$route.params?.id}?active_tab=subtest`;
            } else {
              this.getDataPaketSubtest();
            }
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }
    },
    EditSub() {
      // if (this.formSub.ujian_id == null || this.formSub.ujian_id == "") {
      //   this.pesanGagal();
      //   return false;
      // }

      let payloadUjian = {
        user_id: this.user.id,
        category_paket_id: this.paket.category_id,
        name: this.ujianDataId.name,
        shortname: this.ujianDataId.shortname,
        show_result: this.ujianDataId.show_result,
        shuffle_question: this.ujianDataId.shuffle_question,
        instruction: this.ujianDataId.instruction,
      };

      if (this.id) {
        payloadUjian.id = this.id;
      }

      this.loading = true;
      this.$store
        .dispatch("adminujian/save", [payloadUjian])
        .then((res) => {
          let respon = res.data;
          this.loading = false;
          this.id = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getDataUjian() {
      this.loading = true;
      const payload = {
        category_paket_id: this.paket.category.id,
        mapel_id: this.filterMapel != null ? this.filterMapel.id : null,
        type_ujian: this.filterTipe != null ? this.filterTipe.id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          this.loading = false;
          let ujiandata = response.data.data;
          this.ujiandata = ujiandata;
          ujiandata.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });

          // let cek = ujiandata.map((item) => {
          //   item.id = id
          // })
          //     this.paketSubtestDataItems.find((x) => x.id == cek.id));
          // cek.map((item) => {
          //   item.value = item.id;
          //   item.text = item.name;
          // });
          this.optUjian = ujiandata;

          // this.totalRows = this.ujiandata.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  async mounted() {
    await this.getDataPaketSubtest();
    this.$router.replace({ query: null });
  },
};
</script>

<style></style>
