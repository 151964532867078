var render = function () {
  var _vm$formSubcat, _vm$formBobot;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Harap pahami dahulu alur rasionalisasi Kedinasan "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        var _vm$$route$params;

        $event.preventDefault();
        return _vm.$router.push("/master-admin/rasionalisasi/kedinasan?p=".concat((_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id));
      }
    }
  }, [_vm._v("disini.")])])]), _c('b-card', [_c('header', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Silahkan tentukan "), _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("passing grade")]), _vm._v(" untuk Sub Kategori")]), _vm._v(" yang ditandai sebagai "), _c('strong', [_vm._v("Sub Kategori Psikotest")])]), _c('section', {
    staticClass: "mb-4"
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.subCatFields,
      "items": _vm.subCategories
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(passing_grade)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.mark_as_psikotest == 0 ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Penilaian ditentukan passing grade bobot pada tabel dibawah")])]) : _c('strong', [_vm._v(" " + _vm._s(item.passing_grade) + " ")])];
      }
    }, {
      key: "cell(mark_as_psikotest)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('b-tooltip', {
          attrs: {
            "target": "mark-as-psikotest-on",
            "triggers": "hover"
          }
        }, [_vm._v(" Jika ditandai sebagai psikotest, penilaian ujian di sub kategori ini akan menggunakan bobot persentase ")]), item.mark_as_psikotest == 1 ? _c('span', {
          staticClass: "cursor-pointer bg-success",
          staticStyle: {
            "padding": "5px",
            "border-radius": "20px"
          },
          attrs: {
            "id": "mark-as-psikotest-on"
          }
        }, [_c('feather-icon', {
          staticClass: "text-light",
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "21"
          }
        })], 1) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": "mark-as-psikotest-off",
            "triggers": "hover"
          }
        }, [_vm._v(" Jika tidak ditandai sebagai psikotest, penilaian ujian di sub kategori ini akan menggunakan passing grade di jadwal ")]), item.mark_as_psikotest == 0 ? _c('span', {
          staticClass: "cursor-pointer bg-danger",
          staticStyle: {
            "padding": "5px",
            "border-radius": "20px"
          },
          attrs: {
            "id": "mark-as-psikotest-off"
          }
        }, [_c('feather-icon', {
          staticClass: "text-light",
          attrs: {
            "icon": "XCircleIcon",
            "size": "21"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('strong', [_c('a', {
          staticClass: "text-info",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.editSubcat(item);
            }
          }
        }, [_vm._v("Edit")])])];
      }
    }])
  })], 1), _vm.thereIsNonPsikotestSubcat ? _c('section', {
    staticClass: "mb-2"
  }, [_c('header', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Silahkan tentukan passing grade bobot setiap ujian-ujian dengan Sub Kategori yang tidak ditandai sebagai Psikotest dibawah ini")])]), _c('table', {
    staticClass: "table table-bordered w-100",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Nama Ujian")]), _c('th', [_vm._v("Jumlah Passing Grade Bobot")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.subtestsNonPsikotest, function (subtest, i) {
    var _subtest$ujian, _subtest$bobot;

    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(subtest === null || subtest === void 0 ? void 0 : (_subtest$ujian = subtest.ujian) === null || _subtest$ujian === void 0 ? void 0 : _subtest$ujian.name))]), _c('td', [_c('strong', [_vm._v(_vm._s(subtest === null || subtest === void 0 ? void 0 : (_subtest$bobot = subtest.bobot) === null || _subtest$bobot === void 0 ? void 0 : _subtest$bobot.value))])]), _c('td', [_c('strong', [_c('a', {
      staticClass: "text-info",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.editBobot(subtest, 0);
        }
      }
    }, [_vm._v("Edit")])])])]);
  }), 0)])]) : _vm._e(), _vm.thereIsPsikotestSubcat ? _c('section', {
    staticClass: "mb-2"
  }, [_c('header', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Silahkan tentukan bobot persentase setiap ujian-ujian dengan Sub Kategori yang sudah ditandai sebagai Psikotest dibawah ini")])]), _c('table', {
    staticClass: "table table-bordered w-100",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Nama Ujian")]), _c('th', [_vm._v("Bobot Persentase")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.subtests, function (item, i) {
    var _item$ujian, _item$bobot;

    return _c('tr', {
      key: i
    }, [item.is_parent ? _c('td', {
      attrs: {
        "colspan": "4"
      }
    }, [_c('i', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.name) + ": Total Bobot ("), _c('strong', {
      class: item.total_percentage == 100 ? 'text-success' : ''
    }, [_vm._v(_vm._s(item.total_percentage) + "%")]), _vm._v(") "), item.total_percentage != 100 ? _c('small', {
      staticClass: "text-danger"
    }, [_vm._v("*pastikan total bobot sub kategori 100%")]) : _vm._e()])]) : _vm._e(), !item.is_parent ? _c('th', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.index) + " ")]) : _vm._e(), !item.is_parent ? _c('th', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$ujian = item.ujian) === null || _item$ujian === void 0 ? void 0 : _item$ujian.name) + " ")]) : _vm._e(), !item.is_parent ? _c('th', [_c('strong', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s((_item$bobot = item.bobot) === null || _item$bobot === void 0 ? void 0 : _item$bobot.value_percentage) + "%")])]) : _vm._e(), !item.is_parent ? _c('th', [_c('strong', [_c('a', {
      staticClass: "text-info",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.editBobot(item, 1);
        }
      }
    }, [_vm._v("Edit")])])]) : _vm._e()]);
  }), 0)])]) : _c('section', [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('strong', [_vm._v("Belum terdapat Sub Kategori yang ditandai sebagai Psikotest")]), _vm._v(", silahkan tandai sebagai Psikotest salah satu Sub Kategori untuk mengatur Rasionalisasi Kedinasan ")])], 1)]), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "modal-subcat"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updateSubcat($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('section', [_c('h5', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Sub Kategori: "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s((_vm$formSubcat = _vm.formSubcat) === null || _vm$formSubcat === void 0 ? void 0 : _vm$formSubcat.name))])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Tandai Sub Kategori sebagai "), _c('strong', [_vm._v("Psikotest")]), _vm._v("?")]), _c('b-form-radio', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "name": "mark_as_psikotest",
      "value": true
    },
    model: {
      value: _vm.formSubcat.mark_as_psikotest,
      callback: function callback($$v) {
        _vm.$set(_vm.formSubcat, "mark_as_psikotest", $$v);
      },
      expression: "formSubcat.mark_as_psikotest"
    }
  }, [_vm._v("Ya")]), _c('b-form-radio', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "name": "mark_as_psikotest",
      "value": false
    },
    model: {
      value: _vm.formSubcat.mark_as_psikotest,
      callback: function callback($$v) {
        _vm.$set(_vm.formSubcat, "mark_as_psikotest", $$v);
      },
      expression: "formSubcat.mark_as_psikotest"
    }
  }, [_vm._v("Tidak")])], 1), _vm.formSubcat.mark_as_psikotest ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Passing Grade (1 - 100)")]), _c('b-form-input', {
    model: {
      value: _vm.formSubcat.passing_grade,
      callback: function callback($$v) {
        _vm.$set(_vm.formSubcat, "passing_grade", $$v);
      },
      expression: "formSubcat.passing_grade"
    }
  })], 1) : _vm._e()])]), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "modal-bobot",
      "title": _vm.formBobot.mark_as_psikotest == 1 ? 'Atur Bobot Persentase Subtest' : 'Atur Bobot Passing Grade Subtest'
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updateBobot($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('section', [_c('h5', {
    staticClass: "mb-1"
  }, [_c('i', [_vm._v("Ujian: "), _c('strong', [_vm._v(_vm._s((_vm$formBobot = _vm.formBobot) === null || _vm$formBobot === void 0 ? void 0 : _vm$formBobot.name))])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.formBobot.mark_as_psikotest == 1 ? "Bobot Ujian (dalam %)" : "Bobot Ujian (dalam nominal)"))])]), _vm.formBobot.mark_as_psikotest == 1 ? _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formBobot.value_percentage,
      callback: function callback($$v) {
        _vm.$set(_vm.formBobot, "value_percentage", $$v);
      },
      expression: "formBobot.value_percentage"
    }
  }) : _vm._e(), _vm.formBobot.mark_as_psikotest == 0 ? _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formBobot.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formBobot, "value", $$v);
      },
      expression: "formBobot.value"
    }
  }) : _vm._e()], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }