<template>
  <b-overlay :show="loading">
    <b-card class="mb-1">
      <strong
        >Harap pahami dahulu alur rasionalisasi Bintara
        <a
          href="#"
          @click.prevent="
            $router.push(
              `/master-admin/rasionalisasi/bintara?p=${$route.params?.id}`
            )
          "
          >disini.</a
        ></strong
      >
    </b-card>
    <b-card>
      <header class="mb-1">
        <strong
          >Silahkan tentukan <i class="text-danger">passing grade</i> untuk
          setiap Sub Kategori</strong
        >
      </header>
      <section class="mb-2">
        <b-table :fields="subCatFields" :items="subCategories">
          <template #cell(index)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(action)="{ item }">
            <strong
              ><a href="#" @click.prevent="editPGSubcat(item)" class="text-info"
                >Edit</a
              ></strong
            >
          </template>
        </b-table>
      </section>

      <header class="mb-1">
        <strong
          >Silahkan tentukan
          <i class="text-danger">persentase bobot</i> ujian-ujian di setiap sub
          kategori</strong
        >
      </header>
      <table class="table table-bordered w-100" border="1">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Ujian</th>
            <th>Bobot Persentase</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in subtests" :key="i">
            <td v-if="item.is_parent" colspan="4">
              <i class="text-dark"
                >{{ item.name }}: Total Bobot (<strong
                  :class="item.total_percentage == 100 ? 'text-success' : ''"
                  >{{ item.total_percentage }}%</strong
                >)
                <small class="text-danger" v-if="item.total_percentage != 100"
                  >*pastikan total bobot sub kategori 100%</small
                >
              </i>
            </td>
            <th v-if="!item.is_parent">
              {{ item?.index }}
            </th>
            <th v-if="!item.is_parent">
              {{ item?.ujian?.name }}
            </th>
            <th v-if="!item.is_parent">
              <strong class="text-primary"
                >{{ item.bobot?.value_percentage }}%</strong
              >
            </th>
            <th v-if="!item.is_parent">
              <strong
                ><a href="#" class="text-info" @click.prevent="editBobot(item)"
                  >Edit</a
                ></strong
              >
            </th>
          </tr>
        </tbody>
      </table>
    </b-card>

    <!-- Modal Subcat -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      title="Edit Passing Grade Sub Kategori"
      id="modal-subcat"
    >
      <h5 class="mb-1">
        <strong
          >Sub Kategori:
          <span class="text-danger">{{ formSubcat?.name }}</span></strong
        >
      </h5>
      <div class="form-group">
        <label for="">Passing Grade (1 - 100)</label>
        <b-form-input v-model="formSubcat.passing_grade" />
      </div>

      <template #modal-footer>
        <b-button variant="primary" @click.prevent="updatePassingGrade"
          >Simpan</b-button
        >
      </template>
    </b-modal>
    <!-- / -->

    <!-- Mdal BObot Subtest -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="modal-bobot"
      title="Atur Bobot Persentase Subtest"
    >
      <section>
        <h5 class="mb-1">
          <i
            >Ujian: <strong>{{ formBobot?.name }}</strong></i
          >
        </h5>
        <div class="form-group">
          <label for=""><strong>Bobot Ujian (dalam %)</strong></label>
          <b-form-input type="number" v-model="formBobot.value_percentage" />
        </div>
      </section>

      <template #modal-footer>
        <b-button variant="primary" @click.prevent="updateBobot"
          >Simpan</b-button
        >
      </template>
    </b-modal>
    <!-- / -->
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BButton,
  BFormInput,
  BModal,
  BTable,
  BCard,
} from "bootstrap-vue";
import { numeric } from "vee-validate/dist/rules";
export default {
  components: {
    BOverlay,
    BButton,
    BFormInput,
    BModal,
    BTable,
    BCard,
  },
  data: () => ({
    formSubcat: {
      id: null,
      paket_id: null,
      name: null,
      passing_grade: 0,
    },
    formBobot: {
      id: null,
      name: null,
      paket_id: null,
      subtest_id: null,
      value_percentage: 0,
    },
    loading: false,
    subCategories: [],
    subtests: [
      {
        id: 1,
        name: "Sub kate tes Psikologi",
        is_parent: true,
      },
      {
        id: 1,
        name: "Tes Kecerdasan",
      },
      {
        id: 1,
        name: "Tes Kecermatan",
      },
      {
        id: 1,
        name: "Sub kate tes SKD",
        is_parent: true,
      },
      {
        id: 1,
        name: "Tes Kecermatan",
      },
    ],
    subCatFields: [
      { key: "index", label: "No", sortable: true, class: "text-center" },
      {
        key: "name",
        label: "Sub Kategori",
        sortable: true,
        class: "text-left",
      },
      {
        key: "passing_grade",
        label: "Passing Grade",
        sortable: true,
        class: "text-center",
      },
      { key: "action", label: "#", sortable: false, class: "text-center" },
    ],
  }),
  methods: {
    async updateBobot() {
      // set payload
      const payload = {
        paket_id: this.formBobot.paket_id,
        subtest_id: this.formBobot.subtest_id,
        value: parseFloat(this.formBobot.value_percentage / 100),
      };

      if (this.formBobot.id) {
        payload.id = this.formBobot.id;
      }

      try {
        this.$bvModal.hide("modal-bobot");
        this.loading = true;
        await this.$store.dispatch("rasionalisasi/CUDbintara", [payload]);
        await this.getSubtests();
        this.loading = false;
      } catch (e) {
        this.$bvModal.show("modal-bobot");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    editBobot(subtest) {
      this.formBobot.id = subtest?.bobot.bobot_id;
      this.formBobot.name = subtest?.ujian.name;
      this.formBobot.paket_id = subtest.paket_id;
      this.formBobot.subtest_id = subtest.id;
      this.formBobot.value_percentage = subtest.bobot.value_percentage;

      this.$bvModal.show("modal-bobot");
    },
    resetFormSubcat() {
      this.formSubcat.id = null;
      this.formSubcat.paket_id = null;
      this.formSubcat.name = null;
      this.formSubcat.passing_grade = 0;
    },
    async updatePassingGrade() {
      const payload = this.formSubcat;

      try {
        this.$bvModal.hide("modal-subcat");
        this.loading = true;
        await this.$store.dispatch("adminujian/CUDsubtesKatPaket", [payload]);
        await this.getSubCategories();
        this.resetFormSubcat();
        this.loading = false;
      } catch (e) {
        this.$bvModal.show("modal-subcat");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getSubtests() {
      const params = {
        paket_id: this.$route.params.id,
      };

      let response = await this.$store.dispatch(
        "adminujian/subtesPaketWithBobot",
        params
      );
      response = response?.data;

      // di group berdasarkan subcat
      let subcats = Object.assign([], this.subCategories);
      subcats = subcats.map((subcat) => {
        const subtests = response
          .filter((subtest) => {
            return subtest?.subcat_id == subcat.id;
          })
          .map((subtest, i) => {
            subtest.index = i + 1;
            return subtest;
          });
        subcat.items = subtests;

        return subcat;
      });
      let results = [];
      for (let subcat of subcats) {
        // get total percentage per subcat
        const totalPercentage =
          subcat.items.length > 0
            ? subcat.items.reduce(
                (total, subtest) => (total += subtest.bobot?.value_percentage),
                0
              )
            : 0;
        // save subcat
        results.push({
          id: subcat.id,
          name: subcat.name,
          passing_grade: subcat.passing_grade,
          total_percentage: totalPercentage,
          is_parent: true,
        });
        // save subtests
        if (subcat.items.length > 0) {
          for (let subtest of subcat.items) {
            results.push(subtest);
          }
        }
      }

      this.subtests = results;
    },
    editPGSubcat(subcat) {
      const { id, paket_id, name, passing_grade } = subcat;
      this.formSubcat.id = id;
      this.formSubcat.paket_id = paket_id;
      this.formSubcat.name = name;
      this.formSubcat.passing_grade = passing_grade;

      this.$bvModal.show("modal-subcat");
    },
    async getSubCategories() {
      try {
        const params = {
          paket_id: this.$route.params.id,
        };

        const response = await this.$store.dispatch(
          "paketTryout/getSubcat",
          params
        );
        this.subCategories = response?.data;
        this.getSubtests();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },
  mounted() {
    this.getSubCategories();
  },
};
</script>
