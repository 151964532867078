<template>
  <section>
    <div align="left">
      <b-button
        variant="outline-danger"
        @click.prevent="goBack"
        class="btn-icon mb-1"
      >
        <feather-icon icon="ArrowLeftIcon" class="mr-25" />Kembali</b-button
      >
    </div>
    <b-row
      ><b-col md="12">
        <!-- <b-card> -->
        <b-tabs align="center" v-model="currentTab" fil0>
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Detail</span>
            </template>
            <!-- <app-collapse accordion>
              <app-collapse-item title="Info Paket"> -->
            <b-card title="Info Paket">
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="
                        paketDataId.thumbnail != null
                          ? apiFile + paketDataId.thumbnail
                          : require('@/assets/images/elp/no-image.jpg')
                      "
                      height="80"
                    />
                  </b-link>
                  <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    title="Upload Thumbnail"
                    @click="ModalUpload()"
                    variant="warning"
                    size="sm"
                    class="mb-75 mr-75"
                  >
                    Upload
                  </b-button>

                  <b-modal
                    v-model="ModalUploadThumbnail"
                    id="modal-upload"
                    centered
                    size="lg"
                    :title="titleModal"
                  >
                    <b-card-text>
                      <b-form>
                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label="Thumbnail"
                              label-for="Thumbnail"
                            >
                              <b-form-file
                                id="Thumbnail"
                                v-model="paketDataId.thumbnail"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                no-drop
                                accept="image/*"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left mb-0"></p>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="outline-success"
                          class="mr-1 btn-icon"
                          @click.prevent="UploadThumbnail"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />{{
                            label || "Tambah"
                          }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="float-right btn-icon"
                          @click="
                            ModalUploadThumbnail = false;
                            getDataById();
                          "
                        >
                          <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                  <!-- <b-form-file
                          ref="refInputEl"
                          v-model="profileFile"
                          accept=".jpg, .png, .gif"
                          :hidden="true"
                          plain
                          @input="inputImageRenderer"
                        /> -->
                  <!--/ upload button -->

                  <!-- reset -->
                  <!-- <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                        >
                          Reset
                        </b-button> -->
                  <!--/ reset -->
                  <!-- <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text> -->
                  <b-card-text
                    ><b-badge
                      :variant="
                        paketDataId.category.color != null
                          ? paketDataId.category.color
                          : 'primary'
                      "
                      >{{ paketDataId.category.name }}</b-badge
                    ></b-card-text
                  >
                </b-media-body>
              </b-media>
              <!--/ media -->

              <!-- form -->
              <b-form class="mt-2">
                <b-row>
                  <b-col sm="12" md="12">
                    <b-form-group label="Nama Paket" label-for="nama-paket">
                      <b-form-input
                        v-model="paketDataId.name"
                        placeholder="Nama Paket"
                        name="nama-paket"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label="Harga" label-for="harga">
                      <b-form-input
                        v-model="paketDataId.price"
                        name="price"
                        placeholder="Price"
                        @keyup="doFormatRupiah"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="4">
                    <!-- <b-form-group
                          label="Tanggal Mulai"
                          label-for="info_start"
                        >
                          <flat-pickr
                            v-model="paketDataId.info_start"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                            }"
                          />
                        </b-form-group> -->
                    <b-form-group
                      label="Maksimal Peserta"
                      label-for="max_quota"
                    >
                      <b-form-input
                        v-model="paketDataId.max_quota"
                        @keyup="doFormatRupiah"
                        name="max_quota"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="4">
                    <!-- <b-form-group
                          label="Tanggal Akhir"
                          label-for="info_end"
                        >
                          <flat-pickr
                            v-model="paketDataId.info_end"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                            }"
                          />
                        </b-form-group> -->
                    <b-form-group label="Jumlah Ujian" label-for="sum_subtest">
                      <b-form-input
                        v-model="paketDataId.sum_subtest"
                        disabled
                        name="sum_subtest"
                        type="number"
                      />
                    </b-form-group>
                    <!-- </b-col> -->
                  </b-col>
                  <!-- <b-col sm="3">
                        <b-form-group
                          label="Maksimal Peserta"
                          label-for="max_quota"
                        >
                          <b-form-input
                            v-model="paketDataId.max_quota"
                            @keyup="doFormatRupiah"
                            name="max_quota"
                          />
                        </b-form-group>
                      </b-col> -->
                  <!-- <b-col sm="3">
                        <b-form-group
                          label="Jumlah Subtest"
                          label-for="sum_subtest"
                        >
                          <b-form-input
                            v-model="paketDataId.sum_subtest"
                            name="sum_subtest"
                            type="number"
                          />
                        </b-form-group>
                      </b-col> -->
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group label="Deskripsi" label-for="description">
                      <b-form-textarea
                        v-model="paketDataId.description"
                        name="description"
                        placeholder="Deskripsi"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12">
                    <b-alert variant="primary" show>
                      <div class="alert-body mb-2">
                        <feather-icon
                          class="mr-25"
                          icon="ArrowDownCircleIcon"
                        />
                        <span class="ml-25"
                          ><strong>Pengaturan Tryout</strong>
                        </span>
                      </div>
                    </b-alert>
                    <!-- <b-button block variant="primary" class="mb-2" disabled>
                          Pengaturan Tryout
                        </b-button> -->
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group label="Instruksi" label-for="instruction">
                      <b-form-textarea
                        v-model="paketDataId.instruction"
                        name="instruction"
                        placeholder="Deskripsi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group
                      label="Tipe Rasionalisasi"
                      label-for="rasionalisasi"
                    >
                      <v-select
                        v-model="paketDataId.rasionalisasi_type"
                        label="text"
                        :options="optRasionalisasi"
                        :reduce="(option) => option.value"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group
                      label="Apakah paket terdapat sub kategori?"
                      label-for="is_contain_subcat"
                    >
                      <!-- <label>Apakah paket terdapat sub kategori? <feather-icon icon="HelpCircle" size="18"
                          class="mr-50" /></label> -->
                      <template slot="label">
                        Apakah paket terdapat sub kategori?
                        <feather-icon
                          v-b-tooltip.hover.top="
                            'Khusus paket bintara dan kedinasan, harap menggunakan sub kategori'
                          "
                          icon="HelpCircleIcon"
                          class="ml-50 my-icon"
                        />
                      </template>
                      <b-form-select
                        id="is_contain_subcat"
                        v-model="paketDataId.is_contain_subcat"
                        :options="[
                          { text: 'Ya', value: 1 },
                          { text: 'Tidak', value: 0 },
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group
                      label="Apakah peserta diharuskan memilih jurusan dan rumpun sebelum pengerjaan?"
                      label-for="is_contain_rumpun"
                    >
                      <b-form-select
                        id="is_contain_rumpun"
                        v-model="paketDataId.is_contain_rumpun"
                        :options="[
                          { text: 'Ya', value: 1 },
                          { text: 'Tidak', value: 0 },
                        ]"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click.prevent="Tambah"
                    >
                      Simpan perubahan
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="getDataById()"
                    >
                      Refresh
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
            <!-- </app-collapse-item>
              <app-collapse-item :title="`Member Paket (${members.length})`">
                <member-paket :members="members" :userList="userMemberList" />
              </app-collapse-item>
            </app-collapse> -->
          </b-tab>
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Konten</span>
            </template>
            <b-tabs>
              <!-- Keunggulan -->
              <b-tab active>
                <!-- title -->
                <template #title>
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Keunggulan</span>
                </template>
                <keunggulan-paket :paketDataId="paketDataId" />
              </b-tab>

              <!-- Subtest -->
              <b-tab>
                <!-- title -->
                <template #title>
                  <feather-icon icon="BookOpenIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">Subtest</span>
                </template>
                <detail-paket :paketDataId="paketDataId" />
              </b-tab>
              <!-- FAQ Paket tab -->
              <b-tab>
                <!-- title -->
                <template #title>
                  <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">FAQ</span>
                </template>
                <faq-paket :paketDataId="paketDataId" />
              </b-tab>
            </b-tabs>
          </b-tab>
          <!--/ general tab -->

          <!-- Voucher Paket tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="PercentIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Voucher</span>
            </template>

            <!-- <account-setting-password /> -->

            <voucher-paket :paketDataId="paketDataId" />
          </b-tab>
          <!-- subtes Paket tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LayersIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Ujian</span>
            </template>

            <!-- <account-setting-password /> -->

            <sub-paket :paket="paketDataId" />
          </b-tab>
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="AirplayIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Rasionalisasi</span>
            </template>

            <rasio-sma
              v-if="paketDataId.rasionalisasi_type == 'sma'"
              :paketDataId="paketDataId"
            />
            <rasio-kedinasan
              v-if="paketDataId.rasionalisasi_type == 'kedinasan'"
              :paketDataId="paketDataId"
            />
            <rasio-default
              v-if="paketDataId.rasionalisasi_type == 'default'"
              :paketDataId="paketDataId"
            />
            <rasio-utbk
              v-if="paketDataId.rasionalisasi_type == 'utbk'"
              :paketDataId="paketDataId"
            />
            <rasio-mandiri
              v-if="paketDataId.rasionalisasi_type == 'mandiri'"
              :paketDataId="paketDataId"
            />
            <rasio-bintara
              v-if="paketDataId.rasionalisasi_type == 'bintara'"
              :paketDataId="paketDataId"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Member</span>
            </template>

            <member-paket />
          </b-tab>
        </b-tabs>
        <!-- </b-card> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
//Konten
import FaqPaket from "./konten/faq/Index.vue";
import KeunggulanPaket from "./konten/keunggulan/Index.vue";
import DetailPaket from "./konten/subtest/Index.vue";
//Rasionalisasi
import RasioDefault from "./rasionalisasi/default.vue";
import RasioSma from "./rasionalisasi/sma.vue";
import RasioKedinasan from "./rasionalisasi/kedinasan.vue";
import RasioUtbk from "./rasionalisasi/utbk.vue";
import RasioMandiri from "./rasionalisasi/mandiri.vue";
import RasioBintara from "./rasionalisasi/bintara.vue";
//Hasil
import HasilDefault from "./hasil/default.vue";
import HasilSma from "./hasil/sma.vue";
import HasilKedinasan from "./hasil/kedinasan.vue";
import HasilUtbk from "./hasil/utbk.vue";
//Tab
//Tab
import VoucherPaket from "./voucher/Index.vue";
import SubPaket from "./sub/Index.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import MemberPaket from "./Member.vue";

export default {
  components: {
    BOverlay,
    MemberPaket,
    AppCollapse,
    AppCollapseItem,
    HasilDefault,
    HasilSma,
    HasilKedinasan,
    HasilUtbk,
    RasioDefault,
    RasioSma,
    RasioKedinasan,
    RasioUtbk,
    RasioMandiri,
    RasioBintara,
    vSelect,
    DetailPaket,
    FaqPaket,
    KeunggulanPaket,
    VoucherPaket,
    SubPaket,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loadingmember: false,
      currentTab: 0,
      // userMemberList: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketFAQData: [],
      paketDataId: {
        category: {},
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Pertanyaan", sortable: true },
        { key: "price", label: "Jawaban", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optRasionalisasi: [
        { value: "default", text: "Default" },
        { value: "utbk", text: "UTBK" },
        { value: "kedinasan", text: "Kedinasan" },
        { value: "bintara", text: "Bintara" },
        { value: "mandiri", text: "Mandiri" },
        { value: "sma", text: "SMA" },
      ],
      // optFilter: [
      //   { id: "name", value: "Pertanyaan" },
      //   { id: "price", value: "Jawaban" },
      //   // { id: "nip", value: "NIP" },
      // ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      // filter: null,
      // filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
      // members: [],
      jadwals: [],
    };
  },
  watch: {},
  computed: {
    currentJadwal() {
      return this.$store.state.jadwaladmin.currentJadwal;
    },
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter((f) => f.sortable)
    //     .map((f) => ({ text: f.label, value: f.key }));
    // },
  },
  methods: {
    goBack() {
      if (!this.currentJadwal) {
        this.$router.push("/master-admin/paket-tryout");
      } else {
        this.$router.push(`/master-admin/jadwal-admin/tambah`);
      }
    },
    async getJadwalPaket() {
      const params = {
        paket_id: this.$route.params.id,
      };

      try {
        const response = await this.$store.dispatch(
          "paketTryout/indexJadwal",
          params
        );
        this.jadwals =
          response.data && response.data.data ? response.data.data : [];
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async getUserTypeMember() {
      const params = {
        level_id: 1,
        type_member: "member",
      };

      try {
        const response = await this.$store.dispatch("pengguna/index", params);
        let userList =
          response.data && response.data.data ? response.data.data : [];
        userList = userList.filter((user) => user.name != "");
        userList = userList.map((user) => ({
          id: user.id,
          name: user.name,
        }));
        this.userMemberList = userList;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async getMemberPaket() {
      const params = {
        paket_id: this.$route.params.id,
      };

      try {
        const response = await this.$store.dispatch(
          "paketTryout/indexMemberPaket",
          params
        );
        this.members =
          response.data && response.data.data ? response.data.data : [];
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    doFormatRupiah() {
      this.paketDataId.price = this.formatRupiah(this.paketDataId.price);
      this.paketDataId.max_quota = this.formatRupiah(
        this.paketDataId.max_quota
      );
    },
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.paketDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("paketTryout/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    UploadThumbnail() {
      const fd = new FormData();
      fd.append("thumbnail", this.paketDataId.thumbnail);
      fd.append("name", this.paketDataId.name);
      fd.append("description", this.paketDataId.description);
      fd.append("info_start", this.paketDataId.info_start);
      fd.append("info_end", this.paketDataId.info_end);
      fd.append("price", this.unFormatRupiah(this.paketDataId.price));
      fd.append("max_quota", this.unFormatRupiah(this.paketDataId.max_quota));
      fd.append("category_id", this.paketDataId.category_id);
      if (this.id) {
        fd.append("id", this.$route.params.id);
      }

      this.$store
        .dispatch("paketTryout/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.ModalUploadThumbnail = false;

          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Thumbnail berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    Tambah() {
      if (
        (this.paketDataId.name == null && this.paketDataId.price == null) ||
        (this.paketDataId.name == "" && this.paketDataId.price == "")
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        id: this.$route.params.id,
        name: this.paketDataId.name,
        price: this.unFormatRupiah(this.paketDataId.price),
        description: this.paketDataId.description,
        info_end: this.paketDataId.info_end,
        info_start: this.paketDataId.info_start,
        max_quota: this.unFormatRupiah(this.paketDataId.max_quota),
        category_id: this.paketDataId.category_id,
        rasionalisasi_type: this.paketDataId.rasionalisasi_type,
        is_contain_subcat: this.paketDataId.is_contain_subcat,
        is_contain_rumpun: this.paketDataId.is_contain_rumpun,
        instruction: this.paketDataId.instruction,
      };

      if (this.id) {
        payload.id = this.$route.params.id;
      }

      this.$store
        .dispatch("paketTryout/createUpdateDelete", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Paket berhasil diubah",
          });
          this.paketDataId.name = null;
          this.paketDataId.price = null;
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("paketTryout/indexId", this.$route.params.id)
        .then((response) => {
          let paketDataId = response.data;
          this.paketDataId = paketDataId;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {
    if (this.$route.query.active_tab == "subtest") {
      this.currentTab = 3;
    } else if (this.$route.query.active_tab == "rasionalisasi") {
      this.currentTab = 4;
    }
  },
  async mounted() {
    await this.getDataById();
    this.getJadwalPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
