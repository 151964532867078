<template>
  <b-card>
    <p>
      <strong
        >Halaman ini dimaksudkan untuk pengaturan rasionalisasi terkait dengan
        nilai hasil pengerjaan tryout siswa</strong
      >
    </p>
    <p>
      Untuk bisa membuka pengaturan rasionalisasi, silahkan atur nilai dari
      <strong class="text-danger">Tipe Rasionalisasi</strong> di tab "Detail".
    </p>
    <p>Nilai dari tipe rasionalisasi yang bisa anda pilih:</p>
    <ul type="circle">
      <li>
        <strong>UTBK</strong>: apabila ingin menggunakan penilaian dengan sistem
        UTBK
      </li>
      <li>
        <strong>SMA</strong>: apabila ingin menggunakan penilaian dengan sistem
        di SMA/SMP/SMK
      </li>
      <li>
        <strong>Kedinasan</strong>: apabila ingin menggunakan penilaian dengan
        sistem di Kedinasan
      </li>
      <li>
        <strong>Bintara</strong>: apabila ingin menggunakan penilaian dengan
        sistem di Bintara
      </li>
      <li>
        <strong>Mandiri</strong>: apabila ingin menggunakan penilaian dengan
        sistem di Ujian Mandiri
      </li>
    </ul>
  </b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
};
</script>
