var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Hasil : " + _vm._s(_vm.paketDataId.name))])]), _c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  })])], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('div', [_c('b-table-simple', {
    attrs: {
      "outlined": "",
      "bordered": "",
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "light"
    }
  }, [_c('b-tr', [_c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("No")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Nama")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Sekolah")]), _c('b-th', {
    attrs: {
      "colspan": 3 * _vm.total.totalMapels
    }
  }, [_vm._v("Nilai Mata Pelajaran")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Ranking")]), _c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("Nilai Rata-rata")])], 1), _c('b-tr', _vm._l(_vm.mapels, function (hasil, index) {
    return _c('b-th', {
      key: hasil.id,
      attrs: {
        "colspan": "3"
      }
    }, [_vm._v(_vm._s(hasil.name))]);
  }), 1), _c('b-tr', [_vm._l(_vm.total.totalMapels, function (hasil) {
    return [_c('b-th', [_vm._v("Benar")]), _c('b-th', [_vm._v("Salah")]), _c('b-th', [_vm._v("Nilai")])];
  })], 2)], 1), _c('b-tbody', _vm._l(_vm.rasio, function (hasil, index) {
    return _c('b-tr', {
      key: hasil.id
    }, [_c('b-td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(hasil.name) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(hasil.school_origin == null ? "-" : hasil.school_origin.nama_sekolah) + " ")]), _vm._l(hasil.nilai_per_mapel, function (a, index) {
      return [_c('b-td', {
        attrs: {
          "variant": "success"
        }
      }, [_vm._v(" " + _vm._s(a.jumlah_benar) + " ")]), _c('b-td', {
        attrs: {
          "variant": "danger"
        }
      }, [_vm._v(" " + _vm._s(a.jumlah_salah) + " ")]), _c('b-td', {
        attrs: {
          "variant": "info"
        }
      }, [_vm._v(" " + _vm._s(a.nilai) + " ")])];
    }), _c('b-td', [_vm._v(" " + _vm._s(hasil.ranking.ke) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(hasil.nilai_rata) + " ")])], 2);
  }), 1), _c('b-tfoot', [_c('b-tr', [_c('b-td', {
    staticClass: "text-left",
    attrs: {
      "colspan": 3 * _vm.total.totalMapels + 5,
      "variant": "secondary"
    }
  }, [_vm._v(" Total Siswa: "), _c('b', [_vm._v(_vm._s(_vm.total.totalRecords))])])], 1)], 1)], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }