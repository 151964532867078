var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('p', [_c('strong', [_vm._v("Halaman ini dimaksudkan untuk pengaturan rasionalisasi terkait dengan nilai hasil pengerjaan tryout siswa")])]), _c('p', [_vm._v(" Untuk bisa membuka pengaturan rasionalisasi, silahkan atur nilai dari "), _c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("Tipe Rasionalisasi")]), _vm._v(" di tab \"Detail\". ")]), _c('p', [_vm._v("Nilai dari tipe rasionalisasi yang bisa anda pilih:")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_c('strong', [_vm._v("UTBK")]), _vm._v(": apabila ingin menggunakan penilaian dengan sistem UTBK ")]), _c('li', [_c('strong', [_vm._v("SMA")]), _vm._v(": apabila ingin menggunakan penilaian dengan sistem di SMA/SMP/SMK ")]), _c('li', [_c('strong', [_vm._v("Kedinasan")]), _vm._v(": apabila ingin menggunakan penilaian dengan sistem di Kedinasan ")]), _c('li', [_c('strong', [_vm._v("Bintara")]), _vm._v(": apabila ingin menggunakan penilaian dengan sistem di Bintara ")]), _c('li', [_c('strong', [_vm._v("Mandiri")]), _vm._v(": apabila ingin menggunakan penilaian dengan sistem di Ujian Mandiri ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }